.root {
  margin: 20px auto;

  &:empty {
    margin: 0;
  }
}

.alert {
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
